<template>
<div>
  <div class="box">
    <div>
      <heading
          heading="Plan"
          description="Please select a plan"
      ></heading>
      <div style="float: right;width: 200px;margin-top: -45px;">
        <el-switch
            v-model="pricingMode"
            active-color="#67c23a"
            inactive-color="#67c23a"
            active-text="Monthly"
            inactive-text="Yearly"
        />
      </div>
    </div>
    <div>
      <div v-for="(plan) in plans"
           v-bind:key="'p_plan-'+plan.id"
           style="float: left;width: 100%;text-align: left;">
        <template v-if="plan.pricing && pricingMode === plan.pricing.isMonthly">
          <el-radio
              @change="updatePlanOption(plan.pricing.id)"
              v-model="selectedPlan" :label="plan.pricing.id"
          ><div style="float: left">{{ plan.description }}</div>
          </el-radio>
          <div style="float: right;">
            <strong>${{plan.pricing.price}}</strong>/{{pricingMode ? 'mo': 'year'}} {{plan.pricing.currency}}
          </div>
        </template>
      </div>
    </div>
  </div>
  <el-space direction="vertical"></el-space>
  <div class="box" style="height: 350px;margin-bottom: 30px;">
    <div v-if="paymentMethodsLoadStatus === 2
        && paymentMethods.length > 0">
      <heading
          heading="Select a Card"
          description=""
      ></heading>
      <div style="float: left;text-align: left;width: 100%;">
        <el-radio
            v-for="(method, key) in paymentMethods"
            @change="updatePaymentOption(method.id)"
            v-bind:key="'method-'+key"
            v-model="paymentMethodSelected" :label="method.id"
        >{{ method.brand.charAt(0).toUpperCase() }}{{ method.brand.slice(1) }},
          <span>
          Ending In: {{ method.last_four }} Exp: {{ method.exp_month }} / {{ method.exp_year }}
            <!--          <span><el-button type="danger" @click="removePaymentMethod( method.id )">Delete</el-button></span>-->
        </span>
        </el-radio>
      </div>
    </div>

    <heading
        heading="Add Card"
        description="Please fill in the card details and click 'Add Card'. "
    ></heading>
    <div style="margin-top: 10px;">
      <div>
        <el-form id="payment-form">
          <div style="width: 50%;float:left">
            <div v-if="addPaymentStatusError" style="margin-bottom: 5px;">
              <el-alert :title="addPaymentStatusError" type="error" />
            </div>
            <el-form-item label="">
              <el-input id="card-holder-name" v-model="name" placeholder="Card Holder Name"></el-input>
            </el-form-item>
<!--            <el-form-item label="">-->
              <div id="card-element"></div>
<!--            </el-form-item>-->
          </div>
<!--          <el-form-item style="width: 50%;float:left;padding: 30px;text-align:left">-->
            <el-button id="add-card-button" type="primary" plain @click="submitPaymentMethod">Add Card</el-button>
<!--          </el-form-item>-->
        </el-form>
      </div>
    </div>
    <div v-if="paymentMethodsLoadStatus === 2
    && paymentMethods.length === 0"
         class="">
    </div>
  </div>
  </div>
  <div style="width: 125px;float: right;">
    <el-button :disabled="!selectedPlan || !paymentMethodSelected" class="fl-right" type="primary" @click="updateSubscription">Launch Website</el-button>
  </div>
</template>

<script>
import {ElRadio, ElButton, ElFormItem, ElAlert, ElSpace, ElSwitch, ElInput} from 'element-plus'
import SubscriptionService from "@/api/subscription.service";
import Heading from "@/components/Partials/Heading";

export default {
  name: "Subscription",
  components: {Heading, ElRadio, ElButton, ElFormItem, ElAlert, ElSpace, ElSwitch, ElInput},
  mounted() {
    this.includeStripe('js.stripe.com/v3/', function(){
      this.configureStripe();
    }.bind(this) );
    this.getProducts()
    this.loadIntent()
    this.loadPaymentMethods()
  },
  emits: ["onSubscriptionUpdate"],
  data() {
    return {
      stripeAPIToken: process.env.VUE_APP_STRIPE_PUBLIC_KEY,//'pk_test_nOA2xVZn5iRJuL0RiXCO43kB',
      stripe: '',
      elements: '',
      card: '',
      intentToken: '',
      name: '',
      addPaymentStatus: 0,
      addPaymentStatusError: null,
      paymentMethods: [],
      paymentMethodsLoadStatus: 0,
      paymentMethodSelected: null,
      selectedPlan: null,
      pricingMode: true,
      plans: [],
    }
  },
  computed: {
    planType(isMonthly) {
      return isMonthly === this.pricingMode
    },
  },
  methods: {
    getProducts() {
      SubscriptionService.getProducts().then(response =>{
        this.plans = response.data.data
      })
    },
    updatePlanOption(value) {
      this.selectedPlan = value
    },
    updatePaymentOption(value) {
      this.paymentMethodSelected = value
    },
    submitPaymentMethod() {
      this.addPaymentStatus = 1
      this.stripe.confirmCardSetup(
          this.intentToken.client_secret, {
            payment_method: {
              card: this.card,
              billing_details: {
                name: this.name
              }
            }
          }
      ).then(function(result) {
        if (result.error) {
          this.addPaymentStatus = 3;
          this.addPaymentStatusError = result.error.message;
        } else {
          this.addPaymentStatusError = null;
          this.savePaymentMethod( result.setupIntent.payment_method );
          this.addPaymentStatus = 2;
          this.card.clear();
          this.name = '';
        }
      }.bind(this));
    },
    /*
    Saves the payment method for the user and
    re-loads the payment methods.
    */
    savePaymentMethod( method ){
      SubscriptionService.savePaymentMethod({
        payment_method: method
        // eslint-disable-next-line
      }).then(response =>{
        this.loadPaymentMethods();
      })
    },

    /*
    Loads all of the payment methods for the
    user.
    */
    loadPaymentMethods(){
      this.paymentMethodsLoadStatus = 1;
      SubscriptionService.loadPaymentMethod()
          .then( function( response ){
            this.paymentMethods = response.data;
            if(this.paymentMethods.length > 0) {
              this.updatePaymentOption(this.paymentMethods[this.paymentMethods.length - 1].id)
            }
            this.paymentMethodsLoadStatus = 2;
          }.bind(this));
    },

    removePaymentMethod( paymentID ){
      SubscriptionService.removePaymentMethod({
        id: paymentID
        // eslint-disable-next-line
      }).then( function( response ){
        this.loadPaymentMethods();
      }.bind(this));
    },

    updateSubscription(){
      SubscriptionService.updateSubscription({
        plan: this.selectedPlan,
        payment: this.paymentMethodSelected
      }).then( function( response ){
        let data = {
          selectedPlan: this.selectedPlan,
          pricingMode: (this.pricingMode ? 'monthly': 'yearly'),
          response: response
        }
        this.$emit("onSubscriptionUpdate", data);
      }.bind(this));
    },

    /*
    Includes Stripe.js dynamically
    */
    includeStripe( URL, callback ){
      let documentTag = document, tag = 'script',
          object = documentTag.createElement(tag),
          scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = 'https://' + URL;
      if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    /*
    Configures Stripe by setting up the elements and
    creating the card element.
    */
    configureStripe(){
      // eslint-disable-next-line
      this.stripe = Stripe( this.stripeAPIToken )
      this.elements = this.stripe.elements();
      let style = {
        base: {
          color: "#32325d",
          fontFamily: 'Arial, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#32325d"
          }
        },
        invalid: {
          fontFamily: 'Arial, sans-serif',
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      };
      this.card = this.elements.create('card', {style: style});

      this.card.mount('#card-element');
    },
    /*
    Loads the payment intent key for the user to pay.
    */
    loadIntent(){
      SubscriptionService.getIntent()
          .then(response =>{
            this.intentToken = response.data;
          })
    },
  },
}
</script>

<style scoped>
.box {
  min-height: 290px;
  border: solid 1px #ebeef5;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px;
  padding:15px;
}
#card-holder-name {
  width: 50%;
  max-width: 50%;
}
#card-element > * > iframe {
  width: 600px !important;
}
</style>
