<template>
  <div>
    <!--Step 1. Form comes below-->
    <div class="maindiv">
      <el-form
      ref="ruleForm"
      :model="form"
      status-icon
      :rules="rules"
      label-width="180px"
      class=" login-form"
      :label-position="labelPosition" 
    >
        <heading
            v-bind:heading="heading"
            v-bind:description="description"
        ></heading>
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <el-form-item
            label="First Name"
            prop="firstName"
          >
          <el-input
              v-model="form.firstName"
              type="text"
              autocomplete="off"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-form-item
          label="Last Name"
          prop="lastName"
          >
          <el-input
              v-model="form.lastName"
              type="text"
              autocomplete="off"
            />
          </el-form-item>
        </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <el-form-item
            label="Email"
            prop="email"
          >
          <el-input
              v-model="form.email"
              type="email"
              :onblur="duplicateEmail"
              autocomplete="off"
            />
          </el-form-item>
          <div v-if="duplicateEmailerror">
            <el-alert
              title="Email Already In Use."
              type="error"
              effect="dark"
              
            /><br/>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-form-item
          label="Phone"
          prop="phone"
          >
          <el-input
              v-model="form.phone"
              type="text"
              autocomplete="off"
            />
          </el-form-item>
        </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <el-form-item
            label="Password"
            prop="password"
          >
          <el-input
              v-model="form.password"
              type="password"
              autocomplete="off"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-form-item
          label="Password Confirm"
          prop="confirm_password"
          >
          <el-input
              v-model="form.confirm_password"
              type="password"
              autocomplete="off"
            />
          </el-form-item>
        </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
      <div v-if="passwordConfirm">
            <el-alert
              title="Password and Confirm Password did not match."
              type="error"
              effect="dark"
            /><br/>
          </div>
           
      </el-col>
    </el-row>
      <el-button class="fl-right" @click="next">Next step</el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import RegisterService from "@/api/register.service";
import Heading from "@/components/Partials/Heading";
export default {
  name: "stepAccount",
  components: {Heading},
  props: {
    formProp: {
      firstName: {type: String, default: null},
      lastName: {type: String, default: null},
      email: {type: String, default: null},
      phone: {type: String, default: null},
      password: {type: String, default: null},
      confirm_password: {type: String, default: null},
    }
  },
  emits: ["handleSave"],
  data() {
      var checkfirstName = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the First Name'));
        } else {
          callback();
        }
      };
      var checkEmail = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter a valid Email'));
        } else {
          callback();
        }
      };
      var checkPassword = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Password'));
        } else {
          callback();
        }
      };
      var checkConfirm = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Confirm Password'));
        } else {
          callback();
        }
      };
    return {
      heading: "Account Inormation",
      description: "Enter your username and password details",
      labelPosition: 'top',
      passwordConfirm:false,
      duplicateEmailerror:false,
      emailerror:null,
      form : this.formProp,
      rules: {
          firstName: [
            { validator: checkfirstName, trigger: 'blur' }
          ],
          email: [
            { validator: checkEmail, trigger: 'blur' }
          ], 
          password: [
            { validator: checkPassword, trigger: 'blur' }
          ],
          confirm_password: [
            { validator: checkConfirm, trigger: 'blur' }
          ]
          
        },
    }
  },
  methods: {
    async duplicateEmail(){
      return RegisterService.checkemail(this.form).then(response => {
        this.emailerror=response.data.data;
         if(this.emailerror===false){
          this.duplicateEmailerror=true;
        }else{
         this.duplicateEmailerror=false;
        }
      });
    },
    next() {
     this.$refs['ruleForm'].validate((valid) => {
       if(this.emailerror===false){
          this.duplicateEmailerror=true;    
        }else if(this.form.password!==this.form.confirm_password){
            this.passwordConfirm=true;
        }else if (valid) {          
          this.$emit("handleSave", this.form);
        }else {
          console.log('error submit!!');
          return false;
        }
      });
      
    },
 
  }
}
</script>

<style scoped>

</style>
