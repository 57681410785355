import BaseService from "./base.service";

class RegisterService {
    signup(signup) {
        return BaseService.apiPostRequest('auth/signup', signup);
    }
    checkemail(checkemail) {
        return BaseService.apiPostRequest('auth/checkemail', checkemail);
    }
    websites(websites) {
        return BaseService.apiPostRequest('auth/websites', websites);
    }
    update(id, signup) {
        return BaseService.apiPostRequest('auth/signup/'+id, signup);
    }
    getTemplates(){
        return BaseService.apiGetRequest('auth/gettemplates');
    }
}
export default new RegisterService();