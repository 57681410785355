<template>
  <div>
    <!--Step 2. Fill the website form-->
    <div class="maindiv">
      <el-form
      ref="ruleForm"
      :model="form"
      status-icon
      :rules="rules"
      label-width="180px"
      class=" login-form"
      :label-position="labelPosition" 
    >
        <heading
            v-bind:heading="heading"
            v-bind:description="description"
        ></heading>
    <el-row :gutter="20">
      <el-col :xs="16" :sm="16" :md="16" :lg="8" :xl="8">
        <el-form-item
            label="Template"
            prop="template"
            style="display: flow-root;"
          >
          <el-select v-model="form.template"  placeholder="Select Template" class="fl-left">
            <el-option              
            v-for="(item, index) in templates"
            :key="index"
            :label="item.name+' ('+item.path+')'"
            :value="index"
            />
          </el-select>
          </el-form-item>
          <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
              <el-form-item
                  label="Website Logo"
                  prop="logo"
              >
                <el-upload
                    class="upload-demo"
                    drag
                    :action="imageUploadUrl"
                    list-type="picture"
                    :headers="imageUploadHeaders"
                    name="file"
                    :file-list="fileList"
                    :limit="2"
                    multiple
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :on-success="handleImageUploadSuccess"
                    :on-error="handleImageUploadError"
                    v-model="form.logo"
                >
                  <i class="el-icon-upload" />
                  <div class="el-upload__text">
                    Drop file here or <em>click to upload</em>
                  </div>
                  <template #tip>
                    <div class="el-upload__tip">
                      .jpg/.jpeg/.png files with a size less than 1MB
                    </div>
                  </template>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" v-if="form.template !== null">
        <div>
          <h5 class="fl-left el-form-item__label" style="margin: 0 10px 0 0;">Preview</h5>
          <img v-if="form.template !== null"
               :src="templates[form.template].preview_img" style="max-height: 300px">
          <el-skeleton-item v-if="form.template === null" variant="image" style="height: 300px;border:solid 1px;background-color: #FAFAFA;border-color: lightgray" />
        </div>
      </el-col>
    </el-row>

    
      <el-button class="fl-right" @click="next">Next step</el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import RegisterService from "@/api/register.service";
import authHeader from '@/api/auth-header';
import Heading from "@/components/Partials/Heading";
export default {
  name: "stepWebsite",
  components: {Heading},
  props: {
    formProp: {
      template: {type: Number, default: null},
      themesname:{type: String, default: null},
      logo: {type: String, default: null},
      logonew: {type: String, default: null},
      websiteurl:{type: String, default: null},
      websitename:{type: String, default: null},
    }
  },
  emits: ["handleSave"],
  data() {
      var checkTemplate = (rule, value, callback) => {
        if (value === null) {
          return callback(new Error('Please select a Template.'));
        } else {
          callback();
        }
      };
      var checkLogo = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please add the Logo.'));
        } else {
          callback();
        }
      };
    return {
      heading: "Website Information",
      description: "Enter details for your website",
      labelPosition: 'top',
      imageUploadUrl: process.env.VUE_APP_BASE_API,
      imageUploadHeaders: null,
      fileList:[],
      form : this.formProp,
      templates:[],
         rules: {
          template: [
            { validator: checkTemplate, trigger: 'blur' }
          ],
          logo: [
            { validator: checkLogo, trigger: 'blur' }
          ]         
        },
    }
  },
    mounted(){      
     this.setImageUploadUrl();
     this.getTemplates(); 
  },
  methods: {
     async getTemplates(){
      return RegisterService.getTemplates().then(response => {
        this.templates=response.data.data.data;
      });
      },
    next() {
        this.$refs['ruleForm'].validate((valid) => {
        if(valid) {
          this.form.template_id = this.templates[this.form.template].id;
          this.$emit("handleSave", this.form);
        }else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    setImageUploadUrl() {
        this.imageUploadHeaders = authHeader();
        this.imageUploadUrl = process.env.VUE_APP_BASE_API + 'image-upload';
      },
      handleImageUploadSuccess(response, file, fileList) {
        if(response.code == 200) {
          this.form.logo = response.image_url;
        }
      },
      handleImageUploadError(err, file, fileList) {
        console.log(err);
      },
      handlePreview(file) {
        console.log(file);
        //TODO: Any Preview stuff
      },
      handleRemove(file, fileList) {
        console.log(file, fileList);
        //TODO: Delete the file
      },
  }
}
</script>
<style scoped>
</style>
