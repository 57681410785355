<template>
  <el-container>
    <el-main style="display: inline-flex;">
      <el-col :span="8" class="fl-left">
        <a class="brand-logo" href="/">
          <h2 class="brand-text text-primary ms-1">vStride</h2>
        </a>
        <div class="grid-content bg-purple-dark">
          <img class="img-fluid w-100" :src="createAccount" alt="multi-steps">
        </div>
      </el-col>
      <el-col :span="16" class="width-100per m-t-5per">
        <el-row v-if="userdata">
          <div class="box-card padding-right-20" style="padding: 0 20px; !important;">
            <div class="fl-right">
              <span class="text item"><strong>{{userdata[0].name}}</strong> - {{userdata[0].email}}</span>
              <el-button class="button padding-left-20" type="text" @click="resetAccount">Switch Account?</el-button>
            </div>
          </div>
        </el-row>
        <el-steps :space="200" :active="active" simple :finish-status="finishStatus">
          <el-step title="Account">
          </el-step>
          <el-step title="Website">
          </el-step>
          <el-step title="Final Step"></el-step>
        </el-steps>

        <div class="m-t-20">
        </div>
        <div v-if="active === 0">
          <StepAccount
              v-bind:form-prop="form.step1"
              @handleSave="step_1"
          ></StepAccount>
        </div>
        <div v-if="active === 1">
          <StepWebsite
              v-bind:form-prop="form.step2"
              @handleSave="step_2"
          ></StepWebsite>
        </div>
        <div v-if="active === 2">
          <StepFinal
            v-bind:form-prop="form.step3"
            @handleSave="final"
          ></StepFinal>
        </div>
        <div v-if="active === 3 && websiteLink !==null">
          Congratulations!!<br>
          Website has been created successfully. Please visit the links below to browse the website or to login into admin end.<br>
          <el-link :href="websiteLink" target="_blank">Website Link</el-link><br>
          <el-link :href="websiteLinkAdmin">Website Admin</el-link><br>
        </div>
        <div>
          <el-button v-if="active > 0 && websiteLink === null" class="fl-left" @click="previous">Previous</el-button>
        </div>
      </el-col>
    </el-main>
    <el-footer>Footer</el-footer>
  </el-container>
</template>

<script>
import RegisterService from "@/api/register.service";
import AuthService from "@/api/auth.service";
import StepAccount from "@/layout/components/Register/stepAccount";
import StepWebsite from "@/layout/components/Register/stepWebsite";
import StepFinal from "@/layout/components/Register/stepFinal";

export default {
  name: "index",
  components: {
    StepFinal,
    StepWebsite,
    StepAccount,
  },
  mounted() {
    this.user = this.isLoggedIn()
    if(this.user) {
      this.isDomainAdded().then(
          // eslint-disable-next-line
          (resp) => {
            this.restoreUserData()
            this.next()
          }
      )
      // this.form.step2= data;
    }
  },
  data() {
    return {
      createAccount: require("@/assets/register/create-account.svg"),
      active: 0,
      totalSteps: 3,
      finishStatus: 'success',
      showAlertAdded:false,
      profile_id:null,
      username:null,
      userdata: null,
      form: {
        step1: {firstName: null, lastName: null, email: null, phone: null, password:null, confirm_password:null},
        step2: {template: null, template_id: null, logo: null, profile_id:null, name:null},
        step3: {field1: null, field2: null}
      },
      websiteLink: null,
      websiteLinkAdmin: null,
    }
  },

  methods: {
    restoreUserData() {
      this.userdata = JSON.parse(localStorage.getItem('userdata'))
    },
    resetAccount() {
      localStorage.removeItem('userdata')
      localStorage.removeItem('user')
      this.$router.go()
    },
    isLoggedIn() {
      let user = JSON.parse(localStorage.getItem('user'));
      if(user) {
        return user
      }
      return false
    },
    async isDomainAdded() {
      this.checkRedirectDomain()
      // Fetch user updated data to verify domain
      // eslint-disable-next-line
      await AuthService.me().then(response => {
        this.checkRedirectDomain()
        return true
      });
      return false
    },
    checkRedirectDomain() {
      const userdata = JSON.parse(localStorage.getItem('userdata'))
      if(userdata && userdata[0].domains && userdata[0].domains.length > 0 && userdata[0].domains[0].website_id) {
        // Website already exists. Send user to Dashboard
        this.$router.replace("/")
      }
    },
    next: function () {
      this.active++;
      this.restoreUserData()
    },
    previous: function () {
      this.active--;
    },
    final: async function (data) {
      this.form.step3= data;
      console.log("Call API and register");
      this.registerWebsite()
    },
    registerWebsite() {
      RegisterService.websites(this.form.step2).then(response => {
        this.next();
        this.websiteLink = response.data.data.website;
        this.websiteLinkAdmin = "/"//response.data.data.website_admin;
        // Redirect to login page or dashboard
      });
    },
    step_1: async function (data)  {
      // console.log(this.form.step1);
      this.form.step1= data;
      //Save Data for Step 1
      await RegisterService.signup(this.form.step1).then(user => {
        user.data.accessToken = user.data.access_token;
        AuthService.setLocalStorage(user)
        this.makeLogin(user)
      });
    },
    step_2: async function (data)  {
      this.form.step2= data;
      this.next();
      //Save Data for Step 2
    },
    makeLogin(user) {
      this.$store.dispatch("auth/makeLogin", user.data).then(
          () => {
            this.isDomainAdded().then(()=>{
              this.next()
            })
          },
          // eslint-disable-next-line
          (error) => {
          }
      )
    }
  },
}
</script>
<style>
.maindiv {padding-top: 10px;}
.el-card__body {
  padding: 0 20px !important;
}
</style>
<style scoped lang="scss">
.el-aside {
  width:25%;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 100%;
}
.bottom {
  margin-top: 13px;
  line-height: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.padding-left-20 {
  padding-left: 20px !important;
}
.padding-right-20 {
  padding-right: 20px !important;
}
.fl-right {
  float:right;
}
.fl-left {
  float:left;
}

</style>
