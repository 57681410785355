<template>
  <div class="left-align m-t-20 m-b-20">
    <h1 class="m-0 p-0 ">{{heading}}</h1>
    <div>{{description}}</div>
  </div>
</template>

<script>
export default {
  name: "Heading",
  props: {
    heading: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped>

</style>
