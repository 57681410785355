<template>
  <div>
    <div class="maindiv">
      <el-form
          ref="ruleForm"
          :model="form"
          status-icon
          :rules="rules"
          label-width="180px"
          class="login-form"
      >
        <heading
            v-bind:heading="heading"
            v-bind:description="description"
        ></heading>
      </el-form>
      <Subscription
        @onSubscriptionUpdate="subscriptionUpdated"
      ></Subscription>

    </div>
    <div v-if="subscribed.selectedPlan">
      <el-button class="fl-right" @click="next">Go to Dashboard</el-button>
    </div>
  </div>
</template>

<script>
import {ElButton} from 'element-plus'
import Heading from "@/components/Partials/Heading";
import Subscription from "@/layout/components/Subscription";
export default {
  name: "stepFinal",
  components: {Subscription, Heading, ElButton},
  props: {
    formProp: {
      field1: {type: String, default: null},
      field2: {type: String, default: null},
    }
  },
  emits: ["handleSave"],
  data() {
    return {
      heading: "Subscription",
      description: "Please select a plan and enter card details to continue",
      subscribed: {selectedPlan: null, pricingMode: null, response: null},
      form : this.formProp,
      rules: {},
    }
  },
  methods: {
    subscriptionUpdated(data) {
      this.subscribed = data
      this.next()
    },
    next() {
      this.form.field1 = 'some value for field1';
      this.$emit("handleSave", this.form);
    },
  }
}
</script>

<style scoped>

</style>
